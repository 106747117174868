import * as ga from "../../../../lib/ga";
import React from "react";
import { CategoryIconImg } from "../../atoms/CategoryIcons/CategoryIcons";
import CategoryCardBtn from "../../molecules/Header/CategoryCardBtn/CategoryCardBtn";
import styles from "./ServiceSelectionList.module.css";
import { MUTUAL_TRANSFER_CATEGORIES } from "../../../db/types/common";

export interface ServiceSelectionListPops {
  onCategoryClick: (item: CategoryIconImg) => void;
  exclude?: CategoryIconImg;
}

const sendEvent = (category: string) => {
  ga.event({
    category: "Also Search for",
    action: "Click",
    label: category,
    non_interaction: true,
  });
};
export default function ServiceSelectionList({
  onCategoryClick = () => null,
  exclude,
}: ServiceSelectionListPops) {
  return (
    <div className={styles.service_selection_list}>
      {exclude !== "PRIMARY_SCHOOL" && (
        <CategoryCardBtn
          img="PRIMARY_SCHOOL"
          onClick={() => {
            onCategoryClick("PRIMARY_SCHOOL");
            sendEvent(MUTUAL_TRANSFER_CATEGORIES.PRIMARY_SCHOOL);
          }}
        />
      )}
      {exclude !== "HIGH_SCHOOL" && (
        <CategoryCardBtn
          img="HIGH_SCHOOL"
          onClick={() => {
            sendEvent(MUTUAL_TRANSFER_CATEGORIES.HIGH_SCHOOL);
            onCategoryClick("HIGH_SCHOOL");
          }}
        />
      )}
      {exclude !== "COLLAGE_STAFF" && (
        <CategoryCardBtn
          img="COLLAGE_STAFF"
          onClick={() => {
            sendEvent(MUTUAL_TRANSFER_CATEGORIES.COLLAGE_STAFF);
            onCategoryClick("COLLAGE_STAFF");
          }}
        />
      )}
      {exclude !== "RAILWAY_STAFF" && (
        <CategoryCardBtn
          img="RAILWAY_STAFF"
          onClick={() => {
            sendEvent(MUTUAL_TRANSFER_CATEGORIES.RAILWAY_STAFF);
            onCategoryClick("RAILWAY_STAFF");
          }}
        />
      )}
      {exclude !== "POSTAL_STAFF" && (
        <CategoryCardBtn
          img="POSTAL_STAFF"
          onClick={() => {
            sendEvent(MUTUAL_TRANSFER_CATEGORIES.POSTAL_STAFF);
            onCategoryClick("POSTAL_STAFF");
          }}
        />
      )}
      {exclude !== "NON_TEACHING_STAFF" && (
        <CategoryCardBtn
          img="NON_TEACHING_STAFF"
          onClick={() => {
            sendEvent(MUTUAL_TRANSFER_CATEGORIES.NON_TEACHING_STAFF);
            onCategoryClick("NON_TEACHING_STAFF");
          }}
        />
      )}
    </div>
  );
}
